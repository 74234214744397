<template>
  <v-content>
    <v-container fill-height>
      <v-layout align-center justify-center class="signin_layout">
        <div class="register_wrapper">
            <div class="register_header_wrapper">
              <div class="register_header">
                <img src="@/assets/img/bici/tortue.png" width="40"/>
                <div class="register_title">
                  <small>Tortue | FPSO</small>
                  <span>Under Construction</span>
                </div>
              </div>
            </div>
            <div class="forgot_text">
              <img id="underImg">
              <div class="sub-content">
                <p>
                  UNDER CONSTRUCTION
                </p>
                <span>
                  Content will be available soon
                </span>
              </div>               
            </div>           
          </div>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import '@/assets/stylus/ui/component/_underConstruction.styl'
import __C from '@/primitives/_constant_'
import Loading from '@/mixins/loading.mixin'
import { mapState, mapMutations } from "vuex"
import { FetchStreamService } from "@/services"
export default {
  mixins: [
    Loading,
  ],
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
  },
  data:() => ({
    fetchStreamService: null,
  }),
  beforeCreate() {
    this.loading = true
  },  
  created(){
    this.setChild('')
    this.fetchStreamService = new FetchStreamService()
  },
  mounted(){
    this.loading = false
    this.getFiles()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setChild'
    ]),    
    getFiles(type) {
      let data = {
        token : this.account.token,
        path: '/TORTUE/Images/Under Construction.png'
      }
      this.fetchStreamService.selectImageFile(data)
      .then(response => response.blob())
      .then(blob => {
        let blobURL = URL.createObjectURL(blob)
        const img = document.getElementById(`underImg`)
        img.src = blobURL
      })
    },
  }
}
</script>